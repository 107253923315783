import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({
  name: "404",
})((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100dvh",
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
    pageNotFoundErrorSvg: {
      height: 200,
      maxWidth: "100%",
      marginBottom: theme.spacing(3),
    },
  };
});

export default useStyles;
